import { Fragment } from "react";
import SearchOrCreateSelectInput from "../../core/inputs/SearchOrCreateSelectInput";
import { renderClientString } from "../utils/utils";


function getClientObjectLabel(client) {
    return <Fragment>
        <span><strong>{renderClientString(client)}</strong></span>
        <span className="select-label-secondary-level">
            <br></br>{client.service_locations_display || "No Service Locations"}
        </span>
        {client.labels && client.labels.length !== 0 && <><br></br><span className="select-label-secondary-level">{client.labels.map((label) => label.name).join(", ")}</span></>}
    </Fragment>
}

function clientToClientOption(client) {
    return {value: client.id, label: getClientObjectLabel(client), object: client}
}

function ClientSearchOrCreateSelect(props) {
    return <SearchOrCreateSelectInput
        objectName="Client"
        getObjectLabel={getClientObjectLabel}
        listEndpoint={DjangoUrls["clients:api-clients-search"](window.MARKETPLACE_ENTITY_SLUG)}
        listEndpointKwargs={[]}
        getDetailsEndpoint={(client) => DjangoUrls["clients:api-clients-detail"](window.MARKETPLACE_ENTITY_SLUG, client.id)}
        {...props}
    />
}

export { clientToClientOption };
export default ClientSearchOrCreateSelect
