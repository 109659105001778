import SearchOrCreateSelectInput from "../../core/inputs/SearchOrCreateSelectInput";
import { PriceBookItemTypes } from "../../core/utils/enums";


export function getPriceBookTaxObjectLabel(priceBookItem) {
    return `[${parseFloat(priceBookItem.default_price)}%] ${priceBookItem.description}`
}

export function priceBookTaxToPriceBookTaxOption(priceBookItem) {
    return {value: priceBookItem.id, label: getPriceBookTaxObjectLabel(priceBookItem), object: priceBookItem}
}

export default function PriceBookTaxSearchOrCreateSelect(props) {
    const {limitToQuickBooksSyncedItems} = props

    let endpointKwargs = [
        ["pricebook_item_type", PriceBookItemTypes.tax],
        ["is_active", true],
        ["confirmed", true],
        ["ordering", "description"],
    ]

    if (limitToQuickBooksSyncedItems) {
        endpointKwargs.push(["has_quickbooks_id", true])
    }

    return <SearchOrCreateSelectInput
        objectName="Tax"
        getObjectLabel={getPriceBookTaxObjectLabel}
        listEndpoint={DjangoUrls["pricebook:api-pricebookitem-search"](window.MARKETPLACE_ENTITY_SLUG)}
        listEndpointKwargs={endpointKwargs}
        getDetailsEndpoint={(priceBookItem) => DjangoUrls["pricebook:api-pricebookitem-detail"](window.MARKETPLACE_ENTITY_SLUG, priceBookItem.id)}
        {...props}
    />
}
