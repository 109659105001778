import Spinner from '@legacy/core/components/Spinner';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Component } from "react";
import deepcopy from "rfdc";
import ContactForm from "../clients/forms/ContactForm";
import { PriceBookItemTypes } from '../core/utils/enums';
import { historyHasState, sendDataToServer, validateSendContact, valueIsDefined } from "../core/utils/utils";
import InvoiceDetailsCard from "./components/InvoiceDetailsCard";
import InvoiceForgiveForm from "./forms/InvoiceForgiveForm";
import InvoiceOnlinePaymentForm from "./forms/InvoiceOnlinePaymentForm";
import InvoicePaymentForm from "./forms/InvoicePaymentForm";
import InvoiceSendForm from "./forms/InvoiceSendForm";
import InvoiceVoidForm from "./forms/InvoiceVoidForm";

const PAGE_MODES = {
    VIEW_INVOICE: "VIEW_INVOICE",
    SEND_INVOICE: "SEND_INVOICE",
    FORGIVE_INVOICE: "FORGIVE_INVOICE",
    VOID_INVOICE: "VOID_INVOICE",
    ADD_PAYMENT: "ADD_PAYMENT",
    EDIT_PAYMENT: "EDIT_PAYMENT",
    PAY_ONLINE: "PAY_ONLINE",
    ADD_CONTACT: "ADD_CONTACT",
    EDIT_CONTACT: "EDIT_CONTACT",
}

const PAGE_MODE_SUBTITLES = {
    VIEW_INVOICE: "Invoice Details",
    SEND_INVOICE: "Send Invoice",
    FORGIVE_INVOICE: "Forgive Invoice",
    VOID_INVOICE: "Void Invoice",
    ADD_PAYMENT: "Add Payment",
    EDIT_PAYMENT: "Edit Payment",
    PAY_ONLINE: "Pay Invoice Online",
    ADD_CONTACT: "Add Contact",
    EDIT_CONTACT: "Edit Contact",
}

const PAGE_MODE_BACK_BUTTON_DISPLAY = {
    VIEW_INVOICE: "flex",
    SEND_INVOICE: "none",
    FORGIVE_INVOICE: "none",
    VOID_INVOICE: "none",
    ADD_PAYMENT: "none",
    EDIT_PAYMENT: "none",
    PAY_ONLINE: "none",
    ADD_CONTACT: "none",
    EDIT_CONTACT: "none",
}

const PRIMARY_PAGE_MODES = [PAGE_MODES.VIEW_INVOICE]
const SECONDARY_PAGE_MODES = [
    PAGE_MODES.SEND_INVOICE,
    PAGE_MODES.FORGIVE_INVOICE,
    PAGE_MODES.VOID_INVOICE,
    PAGE_MODES.ADD_PAYMENT,
    PAGE_MODES.EDIT_PAYMENT,
    PAGE_MODES.PAY_ONLINE,
    PAGE_MODES.ADD_CONTACT,
    PAGE_MODES.EDIT_CONTACT,
]

const PAYMENT_PAGE_MODES = [
    PAGE_MODES.ADD_PAYMENT,
    PAGE_MODES.EDIT_PAYMENT,
]

const CONTACT_PAGE_MODES = [
    PAGE_MODES.ADD_CONTACT,
    PAGE_MODES.EDIT_CONTACT,
]

const FORM_DATA_NAMES_BY_MODE = {
    VIEW_INVOICE: "invoiceData",
    SEND_INVOICE: "sendData",
    FORGIVE_INVOICE: "forgiveData",
    VOID_INVOICE: "voidData",
    ADD_PAYMENT: "paymentData",
    EDIT_PAYMENT: "paymentData",
    PAY_ONLINE: "paymentData",
    ADD_CONTACT: "contactData",
    EDIT_CONTACT: "contactData",
}


const SUBMITTING_NAMES_BY_MODE = {
    VIEW_INVOICE: "submittingInvoice",
    SEND_INVOICE: "submittingSend",
    FORGIVE_INVOICE: "submittingForgive",
    VOID_INVOICE: "submittingVoid",
    ADD_PAYMENT: "submittingPayment",
    EDIT_PAYMENT: "submittingPayment",
    PAY_ONLINE: "submittingPayment",
    ADD_CONTACT: "submittingContact",
    EDIT_CONTACT: "submittingContact",
}

const ERROR_NAMES_BY_MODE = {
    VIEW_INVOICE: "invoice",
    SEND_INVOICE: "send",
    FORGIVE_INVOICE: "forgive",
    VOID_INVOICE: "void",
    ADD_PAYMENT: "payment",
    EDIT_PAYMENT: "payment",
    PAY_ONLINE: "payment",
    ADD_CONTACT: "contact",
    EDIT_CONTACT: "contact",
}


class InvoiceDetailsContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || PAGE_MODES.VIEW_INVOICE
        this.paymentMethodOptions = window.PAYMENT_METHODS

        this.stripePromise = loadStripe(window.STRIPE_PUBLISHABLE_KEY, {stripeAccount: window.STRIPE_ACCOUNT_ID})
        this.defaultClientMessage = window.CURRENT_USER?.service_company?.invoice_default_client_message
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            invoiceData: null,

            sendData: {},
            paymentData: {},
            forgiveData: {},
            voidData: {},
            contactData: {},

            selectedSendPhoneContacts: [],
            selectedSendEmailContacts: [],
            selectedOnlinePaymentRecipient: null,

            errors: {
                invoice: {},
                send: {},
                forgive: {},
                void: {},
                payment: {},
                contact: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            sendViaSMS: false,
            sendViaEmail: false,
            sendManually: false,
            inventoryUsed: false,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
        window.confirmUpdatePayment = this.confirmUpdatePayment
        window.confirmDeletePayment = this.confirmDeletePayment
    }

    componentDidMount = async () => {
        if (this.state.invoiceData === null) {
            let invoiceEndpoint

            if (window.USING_PUBLIC_URL === true) {
                invoiceEndpoint = window.PUBLIC_REST_URL
            }
            else {
                invoiceEndpoint = DjangoUrls["invoices:api-invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, window.INVOICE_ID)
            }

            const invoiceResponse = await fetch(invoiceEndpoint)
            const invoice = await invoiceResponse.json()

            const parts = invoice.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
            let inventoryUsed = false

            if (parts.length && window.USING_PUBLIC_URL === false) {
                let params = new URLSearchParams()
                params.append("line_item_type", PriceBookItemTypes.part)
                params.append("track_inventory", true)
                params.append("confirmed", true)

                parts.forEach(part => params.append("description", part.description))

                const inventoryPartsEndpoint = DjangoUrls["pricebook:api-pricebookitem-search"](window.MARKETPLACE_ENTITY_SLUG) + `?${params}`
                const inventoryPartsResponse = await fetch(inventoryPartsEndpoint)
                const inventoryPartsResponseJSON = await inventoryPartsResponse.json()

                inventoryUsed = inventoryPartsResponseJSON.results.length !== 0
            }

            this.setState((state, props) => {
                let updatedState = state
                updatedState.invoiceData = invoice

                // Split line items into their respective lists
                updatedState.invoiceData.service_charges = invoice.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                updatedState.invoiceData.parts = parts
                updatedState.invoiceData.other_charges = invoice.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                updatedState.invoiceData.discounts = invoice.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                updatedState.inventoryUsed = inventoryUsed

                return updatedState
            })
        }

        if (historyHasState(history)) {
            if (document.querySelector(".page-subtitle")) {
                document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[history.state.mode]
            }
            if (document.querySelector(".back-button")) {
                document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            }
            this.setState(history.state)
        }
        else {
            // If the client asks for the send invoice page directly, oblidge
            const desiredMode = new URLSearchParams(document.location.search).get("mode") || ""
            if (desiredMode === "send-invoice") {
                this.handleActionRequest("INVOICE_SEND")
            }
            else if (desiredMode === "pay-online") {
                this.handleActionRequest("INVOICE_PAY_ONLINE")
            }
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue, then=null) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        }, then)
    }

    switchFormMode = (mode) => {
        if (document.querySelector(".page-subtitle")) {
            document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[mode]
        }
        if (document.querySelector(".back-button")) {
            document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[mode]
        }

        if (SECONDARY_PAGE_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }
            else {
                if (newFormMode === PAGE_MODES.ADD_CONTACT) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].attached_to = "external_client"
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].is_ephemeral = false
                }
                else if (newFormMode === PAGE_MODES.SEND_INVOICE && this.defaultClientMessage) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].client_message = this.defaultClientMessage
                }
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    updateSendPhoneSelection = (selectedContacts) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedSendPhoneContacts = selectedContacts
            updatedState.sendData.phone_contacts = selectedContacts

            return updatedState
        })
    }

    updateSendEmailSelection = (selectedContacts) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedSendEmailContacts = selectedContacts
            updatedState.sendData.email_contacts = selectedContacts

            return updatedState
        })
    }

    updateOnlinePaymentRecipientSelection = (selectedContact) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedOnlinePaymentRecipient = selectedContact

            return updatedState
        })
    }

    // Create invoice

    createInvoice = async () => {
        const dataName = "invoiceData"
        const submittingName = "submittingInvoice"
        const errorDictName = "invoice"
        const endpoint = DjangoUrls["invoices:api-invoices-list"](window.MARKETPLACE_ENTITY_SLUG)
        const endpointMethod = "POST"

        const dataManipulator = (data, state) => {
            let preparedData = deepcopy()(data)
            preparedData.is_draft = false

            // Convert job to ID
            if (data.job !== null ) {
                preparedData.job = data.job.id
            }

            // Convert estimate to ID
            if (data.estimate !== null) {
                preparedData.estimate = data.estimate.id
            }

            // Convert service company to slug
            preparedData.service_company = data.service_company.slug

            // Convert service location to ID
            preparedData.service_location = data.service_location.id

            return preparedData
        }

        const onSuccess = (invoice) => {
            const successUrl = DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, invoice.id) + "?mode=send-invoice"
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Invoice "${invoice.custom_id || invoice.id}" created`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })

            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Invoice could not be created",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Send data

    submitSendData = async () => {
        const endpoint = DjangoUrls["invoices:api-invoices-send"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)

        const dataName = "sendData"
        const submittingName = "submittingSend"
        const errorDictName = "send"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Invoice "${this.state.invoiceData.custom_id || this.state.invoiceData.id}" sent`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const dataManipulator = (data, state) => {
            data.send_via_sms = state.sendViaSMS
            data.send_via_email = state.sendViaEmail
            data.send_manually = state.sendManually
            data.phone_contacts = data.phone_contacts || []
            data.email_contacts = data.email_contacts || []

            return data
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Invoice could not be sent",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    forgiveInvoice = async () => {
        const endpoint = DjangoUrls["invoices:api-invoices-forgive"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)

        const dataName = "forgiveData"
        const submittingName = "submittingForgive"
        const errorDictName = "forgive"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Invoice "${this.state.invoiceData.custom_id || this.state.invoiceData.id}" forgiven`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Invoice could not be forgiven",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    voidInvoice = async () => {
        const endpoint = DjangoUrls["invoices:api-invoices-void"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)

        const dataName = "voidData"
        const submittingName = "submittingVoid"
        const errorDictName = "void"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Invoice "${this.state.invoiceData.custom_id || this.state.invoiceData.id}" voided`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Invoice could not be voided",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }
    // Crud payment

    createPayment = async () => {
        const endpoint = DjangoUrls["invoices:api-invoices-payments-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)
        const successUrl = DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)
        this.CUDPayment(endpoint, "POST", successUrl, "created")
    }

    confirmUpdatePayment = async () => {
        window.MicroModal.close("message_modal_accounting_synced_payment_update")
        this.updatePayment(this.state.paymentIDToUpdate)
    }

    updatePayment = async (paymentID) => {
        const endpoint = DjangoUrls["invoices:api-invoices-payments-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id, paymentID)
        const successUrl = DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)
        this.CUDPayment(endpoint, "PUT", successUrl, "updated")
    }

    confirmDeletePayment = async () => {
        window.MicroModal.close("message_modal_accounting_synced_payment_delete")
        this.deletePayment(this.state.paymentIDToDelete)
    }

    deletePayment = async (paymentID) => {
        const endpoint = DjangoUrls["invoices:api-invoices-payments-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id, paymentID)
        const successUrl = DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)
        this.CUDPayment(endpoint, "DELETE", successUrl, "deleted")
    }

    createOnlinePayment = async () => {
        let endpoint
        let successUrl

        if (window.USING_PUBLIC_URL) {
            endpoint = window.PUBLIC_PAYMENTS_URL
            successUrl = window.PUBLIC_URL
        }
        else {
            endpoint = window.PUBLIC_PAYMENTS_URL
            successUrl = DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)
        }
        this.CUDPayment(endpoint, "POST", successUrl, "paid")
    }

    CUDPayment = async (endpoint, endpointMethod, successUrl, toastTitleSuffix) => {
        const dataName = "paymentData"
        const submittingName = "submittingPayment"
        const errorDictName = "payment"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Invoice payment ${toastTitleSuffix}`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Invoice payment could not be ${toastTitleSuffix}`,
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Crud Contact

    createContact = async () => {
        const attachedTo = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].attached_to
        const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef

        const contactData = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]]

        const { isValid, errors } = validateSendContact(
            contactData,
            [populationRef === "updateSendPhoneSelection" ? "phone" : "email"]
        )

        if (isValid) {
            let endpoint

            if (attachedTo === "external_client") {
                endpoint = DjangoUrls["clients:api-clients-contacts-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.service_location.external_client.id)
            }
            else {
                endpoint = DjangoUrls["clients:api-clients-service-locations-contacts-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.service_location.external_client.id, this.state.invoiceData.service_location.id)
            }

            const endpointMethod = "POST"

            const onSuccess = (contact) => {
                let destinationMode = null
                let destinationData = null

                // Set the new contact in state and then grab that state to use in a new form
                if (populationRef === "updateSendPhoneSelection") {
                    destinationMode = PAGE_MODES.SEND_INVOICE
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.phone)) {
                        const newContacts = [...this.state.selectedSendPhoneContacts, contact]
                        destinationData.phone_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }
                else if (populationRef === "updateSendEmailSelection") {
                    destinationMode = PAGE_MODES.SEND_INVOICE
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.email)) {
                        const newContacts = [...this.state.selectedSendEmailContacts, contact]
                        destinationData.email_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }
                else if (populationRef === "updateOnlinePaymentRecipientSelection") {
                    destinationMode = PAGE_MODES.ADD_PAYMENT
                    destinationData = deepcopy()(this.state.paymentData)

                    if (valueIsDefined(contact.email)) {
                        this[populationRef](contact)
                    }
                }

                this.switchToPrimaryForm()
                this.switchToSecondaryForm(destinationMode, destinationData, null)
                this.addToastToQueue({
                    type: "success",
                    size: contact.is_ephemeral ? "lg" : "md",
                    title: `Contact ${contact.is_ephemeral ? "added" : "created"}`,
                    subtitle: `This contact will not be saved to the contact list`
                })
            }
            const onError = () => {
                this.addToastToQueue({
                    type: "error",
                    size: "md",
                    title: `Contact could not be ${contactData.is_ephemeral ? "added" : "created"}`,
                })
            }

            this.createUpdateContact(endpoint, endpointMethod, onSuccess, onError)
        }
        else {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Contact could not be ${contactData.is_ephemeral ? "added" : "created"}`,
            })
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = errors
                return updatedState
            })
        }
    }

    updateContact = async () => {
        const attachedTo = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].attached_to
        const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef
        const dataRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].dataRef

        const { isValid, errors } = validateSendContact(
            this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]],
            [populationRef === "updateSendPhoneSelection" ? "phone" : "email"]
        )

        if (isValid) {
            let endpoint

            if (attachedTo === "external_client") {
                endpoint = DjangoUrls["clients:api-clients-contacts-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.service_location.external_client.id, this.state[dataRef].id)
            }
            else {
                endpoint = DjangoUrls["clients:api-clients-service-locations-contacts-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.service_location.external_client.id, this.state.invoiceData.service_location.id, this.state[dataRef].id)
            }

            const endpointMethod = "PUT"

            const onSuccess = (contact) => {
                let destinationMode = null
                let destinationData = null

                if (populationRef === "updateSendPhoneSelection") {
                    destinationMode = PAGE_MODES.SEND_INVOICE
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.phone)) {
                        const newContacts = [...this.state.selectedSendPhoneContacts, contact]
                        destinationData.phone_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }
                else if (populationRef === "updateSendEmailSelection") {
                    destinationMode = PAGE_MODES.SEND_INVOICE
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.email)) {
                        const newContacts = [...this.state.selectedSendEmailContacts, contact]
                        destinationData.email_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }
                else if (populationRef === "updateOnlinePaymentRecipientSelection") {
                    destinationMode = PAGE_MODES.ADD_PAYMENT
                    destinationData = deepcopy()(this.state.paymentData)

                    if (valueIsDefined(contact.email)) {
                        this[populationRef](contact)
                    }
                }

                this.switchToPrimaryForm()
                this.switchToSecondaryForm(destinationMode, destinationData, null)
                this.addToastToQueue({
                    type: "success",
                    size: "md",
                    title: "Contact updated",
                })
            }
            const onError = () => {
                this.addToastToQueue({
                    type: "error",
                    size: "md",
                    title: "Contact could not be updated",
                })
            }

            this.createUpdateContact(endpoint, endpointMethod, onSuccess, onError)
        }
        else {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Contact could not be updated",
            })
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = errors
                return updatedState
            })
        }
    }

    createUpdateContact = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "contactData"
        const submittingName = "submittingContact"
        const errorDictName = "contact"

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message.endsWith("name, phone, phone_extension, email must make a unique set.")) {
                errorDict["non_field_error"] = "A contact with these details already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, setErrors)
    }

    // Mark Invoice as Sent

    markInvoiceAsSent = async () => {
        const endpoint = DjangoUrls["invoices:api-invoices-mark-as-sent"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)

        const dataName = "sendData"
        const submittingName = "submittingSend"
        const errorDictName = "send"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Invoice "${this.state.invoiceData.custom_id || this.state.invoiceData.id}" marked as sent`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const dataManipulator = (data, state) => {
            data.phone_contacts = data.phone_contacts || []
            data.email_contacts = data.email_contacts || []
            return data
        }

        document.querySelectorAll("#message_modal_mark_as_sent .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_mark_as_sent .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Invoice could not be marked as sent",
            })
            const markAsInvoicedModal = document.querySelector("#message_modal_mark_as_sent .modal__close")
            if (markAsInvoicedModal) {
                markAsInvoicedModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "INVOICE_CREATE":
                this.createInvoice()
                break
            case "INVOICE_EDIT":
                location.assign(DjangoUrls["invoices:invoices-update"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id))
                break
            case "INVOICE_SEND":
                this.switchToSecondaryForm(PAGE_MODES.SEND_INVOICE, null, null)
                break
            case "TOGGLE_SEND_VIA_SMS":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.sendViaSMS = !state.sendViaSMS
                    return updatedState
                })
                break
            case "TOGGLE_SEND_VIA_EMAIL":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.sendViaEmail = !state.sendViaEmail
                    return updatedState
                })
                break
            case "TOGGLE_SEND_MANUALLY":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.sendManually = !state.sendManually
                    return updatedState
                })
                break
            case "INVOICE_SEND_SUBMIT":
                if (!(this.state.sendViaSMS || this.state.sendViaEmail || this.state.sendManually)) {
                    // Check that at least one method is selected
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.errors.send.deliveryMethod = "Please select at least one delivery method."
                        return updatedState
                    })
                }
                else {
                    if (this.state.invoiceData.state_label === "Sent" || this.state.sendViaSMS || this.state.sendViaEmail) {
                        this.submitSendData()
                    }
                    else {
                        // Mark as sent if it's not yet sent or if they choose not to send it via SMS or Email
                        window.markInvoiceAsSent = this.markInvoiceAsSent
                        document.querySelector("#message_modal_mark_as_sent").style.display = ""
                        window.MicroModal.show("message_modal_mark_as_sent")
                    }
                }
                break
            case "INVOICE_FORGIVE":
                this.switchToSecondaryForm(PAGE_MODES.FORGIVE_INVOICE, null, null)
                break
            case "INVOICE_VOID":
                this.switchToSecondaryForm(PAGE_MODES.VOID_INVOICE, null, null)
                break
            case "INVOICE_FORGIVE_SUBMIT":
                this.forgiveInvoice()
                break
            case "INVOICE_VOID_SUBMIT":
                this.voidInvoice()
                break
            case "INVOICE_DOWNLOAD_PDF":
                location.assign(window.PUBLIC_PDF_URL)
                break
            case "INVOICE_ADD_PAYMENT":
                this.switchToSecondaryForm(PAGE_MODES.ADD_PAYMENT, null, null)
                break
            case "INVOICE_PAY_ONLINE":
                this.switchToSecondaryForm(PAGE_MODES.PAY_ONLINE, null, null)
                break
            case "ADD_PAYMENT":
                this.createPayment()
                break
            case "EDIT_PAYMENT":
                if (this.state.paymentData.is_accounting_synced) {
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.paymentIDToUpdate = this.state.paymentData.id
                        return updatedState
                    })
                    document.querySelector("#message_modal_accounting_synced_payment_update").style.display = ""
                    window.MicroModal.show("message_modal_accounting_synced_payment_update")
                }
                else {
                    this.updatePayment(this.state.paymentData.id)
                }
                break
            case "DELETE_PAYMENT":
                if (this.state.paymentData.is_accounting_synced) {
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.paymentIDToDelete = this.state.paymentData.id
                        return updatedState
                    })
                    document.querySelector("#message_modal_accounting_synced_payment_delete").style.display = ""
                    window.MicroModal.show("message_modal_accounting_synced_payment_delete")
                }
                else {
                    this.deletePayment(this.state.paymentData.id)
                }
                break
            case "PAY_ONLINE":
                this.createOnlinePayment()
                break
            case "CONTACT_CREATE":
                this.createContact()
                break
            case "CONTACT_UPDATE":
                const dataRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].dataRef

                if (this.state[dataRef].is_ephemeral) {
                    this.createContact()
                }
                else {
                    this.updateContact()
                }
                break
            case "CONTACT_CANCEL_CREATE":
            case "CONTACT_CANCEL_EDITS":
                const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef

                let destinationMode = null
                let destinationData = null

                if (populationRef === "updateSendPhoneSelection") {
                    destinationMode = PAGE_MODES.SEND_INVOICE
                    destinationData = deepcopy()(this.state.sendData)
                }
                else if (populationRef === "updateSendEmailSelection") {
                    destinationMode = PAGE_MODES.SEND_INVOICE
                    destinationData = deepcopy()(this.state.sendData)
                }
                else if (populationRef === "updateOnlinePaymentRecipientSelection") {
                    destinationMode = PAGE_MODES.ADD_PAYMENT
                    destinationData = deepcopy()(this.state.paymentData)
                }

                this.switchToPrimaryForm()
                this.switchToSecondaryForm(destinationMode, destinationData, null)
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.invoiceData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_PAGE_MODES.includes(this.state.mode)) {
                return <InvoiceDetailsCard
                    invoice={this.state.invoiceData}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    returnScroll={this.state.returnScroll}
                    submitting={this.state.submittingInvoice}
                    errors={this.state.errors.invoice}
                ></InvoiceDetailsCard>
            }
            else if (this.state.mode === PAGE_MODES.SEND_INVOICE) {
                return <InvoiceSendForm
                    invoice={this.state.invoiceData}
                    sendData={this.state.sendData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    submitting={this.state.submittingSend}
                    errors={this.state.errors.send}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("sendData", fieldName, fieldValue)}
                    updateSendPhoneSelection={this.updateSendPhoneSelection}
                    updateSendEmailSelection={this.updateSendEmailSelection}
                    selectedSendPhoneContacts={this.state.selectedSendPhoneContacts}
                    selectedSendEmailContacts={this.state.selectedSendEmailContacts}
                    sendViaSMS={this.state.sendViaSMS}
                    sendViaEmail={this.state.sendViaEmail}
                    sendManually={this.state.sendManually}
                    returnScroll={0}
                ></InvoiceSendForm>
            }
            else if (this.state.mode === PAGE_MODES.FORGIVE_INVOICE) {
                return <InvoiceForgiveForm
                    invoice={this.state.invoiceData}
                    forgiveData={this.state.forgiveData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingForgive}
                    errors={this.state.errors.forgive}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("forgiveData", fieldName, fieldValue)}
                    inventoryUsed={this.state.inventoryUsed}
                    returnScroll={0}
                ></InvoiceForgiveForm>
            }
            else if (this.state.mode === PAGE_MODES.VOID_INVOICE) {
                return <InvoiceVoidForm
                    invoice={this.state.invoiceData}
                    voidData={this.state.voidData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingVoid}
                    errors={this.state.errors.void}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("voidData", fieldName, fieldValue)}
                    inventoryUsed={this.state.inventoryUsed}
                    returnScroll={0}
                ></InvoiceVoidForm>
            }
            else if (PAYMENT_PAGE_MODES.includes(this.state.mode)) {
                return (
                    <Elements stripe={this.stripePromise}>
                        <InvoicePaymentForm
                            mode={this.state.mode}
                            submitting={this.state.submittingPayment}
                            invoice={this.state.invoiceData}
                            payment={this.state.paymentData}
                            errors={this.state.errors.payment}
                            onFormDataChange={(fieldName, fieldValue, then=null) => this.updateFormData("paymentData", fieldName, fieldValue, then)}
                            requestAction={this.handleActionRequest}
                            switchToPrimaryForm={this.switchToPrimaryForm}
                            switchToSecondaryForm={this.switchToSecondaryForm}
                            paymentMethodOptions={this.paymentMethodOptions}
                            updateOnlinePaymentRecipientSelection={this.updateOnlinePaymentRecipientSelection}
                            selectedOnlinePaymentRecipient={this.state.selectedOnlinePaymentRecipient}
                            returnScroll={0}
                        ></InvoicePaymentForm>
                    </Elements>
                )
            }
            else if (this.state.mode === PAGE_MODES.PAY_ONLINE) {
                return (
                    <Elements stripe={this.stripePromise}>
                        <InvoiceOnlinePaymentForm
                            mode={this.state.mode}
                            submitting={this.state.submittingPayment}
                            invoice={this.state.invoiceData}
                            errors={this.state.errors.payment}
                            onFormDataChange={(fieldName, fieldValue, then=null) => this.updateFormData("paymentData", fieldName, fieldValue, then)}
                            requestAction={this.handleActionRequest}
                            switchToPrimaryForm={this.switchToPrimaryForm}
                            returnScroll={0}
                        ></InvoiceOnlinePaymentForm>
                    </Elements>
                )
            }
            else if (CONTACT_PAGE_MODES.includes(this.state.mode)) {
                return <ContactForm
                    mode={this.state.mode}
                    submitting={this.state.submittingContact}
                    contact={this.state.contactData}
                    errors={this.state.errors.contact}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("contactData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    returnScroll={0}
                ></ContactForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default InvoiceDetailsContainer;
