import SearchOrCreateSelectInput from "../../core/inputs/SearchOrCreateSelectInput";
import { renderServiceLocationString } from "../utils/utils";


function getServiceLocationObjectLabel(serviceLocation) {
    return renderServiceLocationString(serviceLocation)
}

function serviceLocationToServiceLocationOption(serviceLocation) {
    return {value: serviceLocation.id, label: getServiceLocationObjectLabel(serviceLocation), object: serviceLocation}
}

function ServiceLocationSearchOrCreateSelect(props) {
    const {client, extraKey} = props

    return <SearchOrCreateSelectInput
        renderKey={`service_location_select_for_client_${client.id}${extraKey ? "_" + extraKey : ""}`}
        objectName="Location"
        getObjectLabel={getServiceLocationObjectLabel}
        listEndpoint={DjangoUrls["clients:api-clients-service-locations-search"](window.MARKETPLACE_ENTITY_SLUG, client.id)}
        listEndpointKwargs={[]}
        getDetailsEndpoint={(serviceLocation) => DjangoUrls["clients:api-clients-service-locations-detail"](window.MARKETPLACE_ENTITY_SLUG, client.id, serviceLocation.id)}
        {...props}
    />
}

export { serviceLocationToServiceLocationOption };
export default ServiceLocationSearchOrCreateSelect
